import React from 'react'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../../pages/e-books.module.css'

const RedFlagsPage = () => {
  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">Green Flags vs. Red Flags</h1>
        </div>
      </section>
      <section className="content pt-16">
        <div className="container mx-auto px-4 !max-w-[100%]">
          <div className="flex flex-col md:flex-row gap-12">
            <div className="w-full md:w-1/4">
              <StaticImage 
                src="../../images/red-flags-cover.jpg"
                alt="Green Flags vs. Red Flags Cheatsheet Cover"
                className="w-full rounded-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
                <h2 className="text-3xl font-bold text-gray-900 !mt-0">The Ultimate Guide to Green & Red Flags in Women</h2>
                <a 
                  href="https://marciancx.gumroad.com/l/red-flags"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-800 text-white px-4 py-4 rounded-lg hover:bg-red-900 transition-colors whitespace-nowrap"
                >
                  Get your FREE copy!
                </a>
              </div>
              <div className="space-y-4">
                <p className="text-gray-700 text-base">
                  Most men don't fail in relationships because they missed the signs—they fail because they <strong>ignored them.</strong>
                </p>
                <p className="text-gray-700 text-base">
                  This no-nonsense guide breaks down <strong>the key behaviors, traits, and patterns</strong> that separate 
                  <strong> a godly, feminine, supportive woman</strong> from <strong>a problem woman who will drain your peace and destroy your future.</strong>
                </p>
                <p className="text-gray-700 text-base">
                  Inside, you'll learn:<br />
                  • The <strong>green flags</strong> that prove she's a woman worth building with<br />
                  • The <strong>red flags</strong> that signal you need to cut her off immediately<br />
                  • Why most men <strong>justify bad behavior and pay the price later</strong><br />
                  • How to <strong>trust your instincts and make the right choice early</strong>
                </p>
                <p className="text-gray-700 text-base mb-8">
                  <strong>A good woman makes your life better. A bad one will ruin it. Choose wisely.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RedFlagsPage 